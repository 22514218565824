import {
  onCLS,
  onFID,
  onLCP,
  onINP,
  onFCP,
  onTTFB,
} from "web-vitals";

const collectedMetrics = {};

function register({ name, value, rating }) {
  collectedMetrics[name] = { name, value, rating };
}

function track(event) {
  if (!collectedMetrics) return;
  if (event.type === "visibilitychange" && document.visibilityState !== "hidden") return;

  window.removeEventListener("visibilitychange", track);
  window.removeEventListener("pagehide", track);

  navigator.sendBeacon(window.generika.rumReportingUrl, JSON.stringify({
    metrics: Object.values(collectedMetrics),
    "device.type": window.generika.channel,
    "environment.platform.name": "generika",
    "content.type": window.generika.contentType,
    "environment.experiments": undefined,
  }));
}

function setupListeners() {
  window.addEventListener("visibilitychange", track);
  window.addEventListener("pagehide", track); // safari
}

function initRumReporter() {
  const { rumReportingUrl, channel } = window.generika || {};
  if (!rumReportingUrl || channel !== "mobile") return;

  onCLS(register);
  onFCP(register);
  onFID(register);
  onINP(register);
  onLCP(register);
  onTTFB(register);

  setupListeners();
}

export { initRumReporter };
