// @ts-check
import HTMLParsedElement from "../html-parsed-element.js";

const TAG_NAME = "gen-slideshow";

class Slideshow extends HTMLParsedElement {
  constructor() {
    super();
    this.visibleSlides = new Set();
  }
  parsedCallback() {
    this.init();
  }

  init() {
    const template = this.querySelector("template");
    if (!template) {
      throw new Error("No template found");
    }
    this.appendChild(template.content.cloneNode(true));
    this.prevButton = this.querySelector(".slideshow__button-prev");
    this.nextButton = this.querySelector(".slideshow__button-next");
    this.slidesContainer =
          /** @type {HTMLElement | null} */
          (this.querySelector(".slideshow__items"));
    if (!this.slidesContainer) {
      throw new Error("No slides container found");
    }
    this.slides = this.slidesContainer.querySelectorAll(".slideshow__item");
    const observer = new IntersectionObserver(
      this.handleSlideVisibility.bind(this),
      { root: this.slidesContainer, threshold: 0.1 }
    );

    this.slides.forEach((slide) => observer.observe(slide));
    this.addEventListener("click", this.handleClick.bind(this));
    this.classList.add("slideshow--active");
  }

  prevSlide() {
    if (!this.slidesContainer) {
      return;
    }
    this.slidesContainer.scrollLeft -= this.slidesContainer.offsetWidth;
  }

  nextSlide() {
    if (!this.slidesContainer) {
      return;
    }
    this.slidesContainer.scrollLeft += this.slidesContainer.offsetWidth;
  }

  /**
   * Tracks visibility of slides.
   *
   * @param {IntersectionObserverEntry[]} entries
   */
  handleSlideVisibility(entries) {
    const [ entry ] = entries;
    const { target, isIntersecting } = entry;
    if (isIntersecting) {
      this.visibleSlides.add(target);
      target.classList.add("slideshow__item--visible");
    } else {
      this.visibleSlides.delete(target);
      target.classList.remove("slideshow__item--visible");
    }
    this.updateButtonStates();
  }

  updateButtonStates() {
    if (!(this.slidesContainer && this.slides && this.slides.length > 0)) {
      return;
    }
    const firstSlide = this.slides[0];
    if (this.prevButton) {
      this.prevButton.setAttribute("aria-disabled", this.visibleSlides.has(firstSlide) ? "true" : "false");
    }
    const lastSlide = this.slides[this.slides.length - 1];
    if (this.nextButton) {
      this.nextButton.setAttribute("aria-disabled", this.visibleSlides.has(lastSlide) ? "true" : "false");
    }
  }

  /**
   * Handles click events.
   *
   * @param {MouseEvent} event
   */
  handleClick(event) {
    if (event.type !== "click" || !(event.target instanceof Element)) {
      return;
    }
    const { target } = event;
    const button = target && target.closest(".slideshow__button");
    if (!button) {
      return;
    }

    if (button.matches(".slideshow__button-next")) {
      this.nextSlide();
    } else if (button.matches(".slideshow__button-prev")) {
      this.prevSlide();
    }
  }
}

export {
  TAG_NAME,
  Slideshow,
};
